import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { WorkflowStatus } from '@/interface/workflow'

import { getRunningJob, getJobByID } from '@/service/job'
import { getWorkflowById } from '@/service/workflows'
import { postMessage } from '@/utils/utils'

interface IState {
  workflowStatus: WorkflowStatus
  isPulling: boolean
  setPulling: (isPulling: boolean) => void
  setStatus: (workflowStatus: WorkflowStatus) => void
  fetchStatus: (id: string) => void
  pullingStatus: (id: string, curStatus: WorkflowStatus) => void
}

const workflowStatusStore = create<IState>()(
  devtools(set => ({
    workflowStatus: 'Draft',
    isPulling: false,
    setPulling: (isPulling: boolean) => set({ isPulling }),
    setStatus: (workflowStatus: WorkflowStatus) => set({ workflowStatus }),
    fetchStatus: async (id: string) => {
      let status: WorkflowStatus = 'Draft'
      const res = await getRunningJob(id)
      if (res) {
        status = 'Debugging'
        postMessage('debugging=1')
      } else {
        const response = await getWorkflowById(id)
        status = response?.state === 1 ? 'Published' : response.debugState ? 'DebuggingSuccess' : 'Draft'
        postMessage('debugging=0')
      }
      set({ workflowStatus: status })
    },
    pullingStatus: async (id: string, curStatus: WorkflowStatus) => {
      let jobId = ''
      let status: WorkflowStatus = curStatus
      set({ isPulling: true })
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const res = await getRunningJob(id)
        if (!res || workflowStatusStore.getState().isPulling === false) {
          postMessage('debugging=0')
          break
        } else {
          set({ workflowStatus: 'Debugging' })
          postMessage('debugging=1')
          jobId = res?.jobId
          // eslint-disable-next-line no-await-in-loop
          await delay(1000)
        }
      }
      if (jobId) {
        const res = await getJobByID(jobId)
        status = res?.status === 'COMPLETED' ? 'DebuggingSuccess' : 'Draft'
      }
      set({ workflowStatus: status })
    }
  }))
)

export default workflowStatusStore

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
